import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEmergencyStart,
  resetEmergencyState,
  setEmergencyData,
} from '../reducers/emergencyReducer';
import { EmergencyService } from '../services/emergency';
import { getCode, isAuthenticated, logout } from '../services/auth';
import { REDIRECT_PATH } from '../constants/constants';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store/store';
import { resetClientState } from '../reducers/clientReducer';
import { logoutClosed, logoutForce } from '../services/ampolitudeTracker';
import { toast } from 'react-toastify';
import { useEffect, useRef } from 'react';

const useFetchEmergency = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, emergency } = useSelector(
    (state: RootState) => state.emergency
  );
  const { user } = useSelector((state: RootState) => state.client);
  const code = getCode();

  const workerRef = useRef<Worker | null>(null);

  const startWorker = () => {
    if (workerRef.current) return;

    workerRef.current = new Worker(
      new URL('./workers/emergencyWorker.ts', import.meta.url)
    );

    workerRef.current.onmessage = async (event) => {
      if (event.data === 'fetchEmergency') {
        return;
      }
      if (!emergency?.status || emergency?.status === 'closed') {
        if (code && emergency?.openCode === code) {
          logoutClosed(user, emergency);
          dispatch(resetClientState());
          dispatch(resetEmergencyState());
          logout();
          navigate(REDIRECT_PATH);
        }
        return stopWorker();
      }
      await fetchEmergency();
    };

    workerRef.current.postMessage({ action: 'start' });
  };

  const stopWorker = () => {
    if (workerRef.current) {
      workerRef.current.postMessage({ action: 'stop' });
      workerRef.current.terminate();
      workerRef.current = null;
    }
  };

  const fetchEmergency = async () => {
    if (loading) return;

    if (!isAuthenticated() || !code) {
      stopWorker();
      dispatch(resetClientState());
      dispatch(resetEmergencyState());
      logout();
      return navigate(REDIRECT_PATH);
    }

    dispatch(fetchEmergencyStart());

    const response = await EmergencyService.getEmergency(code);

    if (response.data) {
      dispatch(setEmergencyData(response.data));
    } else if (response.error) {
      if (response.error.status === 403) {
        logoutClosed(user, emergency);
      } else {
        toast.error(response.error.message);
        logoutForce(user, emergency, response.error.message);
      }
      dispatch(resetClientState());
      dispatch(resetEmergencyState());
      logout();
      navigate(REDIRECT_PATH);
    }
  };

  useEffect(() => {
    if (!isAuthenticated() || !code) {
      stopWorker();
    }
  }, [isAuthenticated(), code]);

  useEffect(() => {
    if (!emergency?.status || emergency.status !== 'open') return;

    if (!loading) {
      startWorker();
    }

    return () => {
      stopWorker();
    };
  }, [emergency?.status, loading]);

  return fetchEmergency;
};

export default useFetchEmergency;
