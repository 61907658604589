import React, { useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ClientDetails from '../../components/Client/ClientDetails';
import EmergencyDetails from '../../components/Emergency/EmergencyDetails';
import MapComponent from '../../components/Map/MapComponent';
import { getCode, isAuthenticated, logout } from '../../services/auth';
import useFetchClient from '../../actions/client';
import useFetchEmergency from '../../actions/emergency';
import useFetchEmergencyLocation from '../../actions/emergencyLocation';
import styles from './DashboardPage.module.css';
import { RootState } from '../../store/store';
import { REDIRECT_PATH } from '../../constants/constants';
import { EmergencyService } from '../../services/emergency';
import PanicComponent from '../../components/Panic/PanicComponent';
import { resetEmergencyState } from '../../reducers/emergencyReducer';
import { resetClientState } from '../../reducers/clientReducer';
import {
  closeEmergencyFail,
  closeEmergencySuccess,
} from '../../services/ampolitudeTracker';

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientLocation, emergency } = useSelector(
    (state: RootState) => state.emergency
  );
  const code = getCode();
  const { user } = useSelector((state: RootState) => state.client);

  const fetchEmergency = useFetchEmergency();
  const fetchClient = useFetchClient();
  const fetchEmergencyLocation = useFetchEmergencyLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated()) {
        navigate(REDIRECT_PATH);
        return;
      }

      if (!user && !emergency) {
        try {
          await fetchEmergency();
          await fetchClient();
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchEmergencyLocation();
  }, []);

  const handleFinalize = async () => {
    const [lat, lng] = clientLocation
      ? clientLocation.split(',').map(Number)
      : emergency
        ? emergency.startLocation.split(',').map(Number)
        : [0, 0];
    if (code) {
      await EmergencyService.endEmergency(code, lat, lng).then((res) => {
        if (res.error) {
          closeEmergencyFail(res.error.message, user, emergency);
        } else {
          closeEmergencySuccess(user, emergency);
        }
      });
      dispatch(resetClientState());
      dispatch(resetEmergencyState());
      logout();
      navigate(REDIRECT_PATH);
    }
  };

  return (
    <div className={styles['dashboard-container']}>
      <PanicComponent />
      <div className={styles['details-container']}>
        <ClientDetails />
        <div className={styles['map-container']}>
          <MapComponent />
        </div>
      </div>
      <div className={styles['map-section']}>
        <EmergencyDetails />
        {emergency?.status === 'open' && emergency?.openCode === code && (
          <div className={styles['button-section']}>
            <button className={styles['finalize-btn']} onClick={handleFinalize}>
              {t('finalizeEmergencyCall')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
